<template>
<div>

    <h1>Contact Us Forms</h1>
    <el-table :data="items">
        <el-table-column prop="createdAt" label="createdAt"></el-table-column>
        <el-table-column prop="name" label="name"></el-table-column>
        <el-table-column prop="surname" label="surname"></el-table-column>
        <el-table-column prop="phone" label="phone"></el-table-column>
        <el-table-column prop="email" label="email"></el-table-column>
        <el-table-column prop="text" label="text"></el-table-column>
        <el-table-column prop="currentPage" label="currentPage"></el-table-column>
        <el-table-column prop="campaignFirst" label="campaignFirst"></el-table-column>
        <el-table-column prop="campaignLast" label="campaignLast"></el-table-column>
        <el-table-column prop="sourceFirst" label="sourceFirst"></el-table-column>
        <el-table-column prop="sourceLast" label="sourceLast"></el-table-column>
        <el-table-column prop="sessionId" label="sessionId"></el-table-column>
        <el-table-column prop="deviceId" label="deviceId"></el-table-column>
        <el-table-column prop="firstVisitedPage" label="firstVisitedPage"></el-table-column>
        <el-table-column prop="todayFirstVisitedPage" label="todayFirstVisitedPage"></el-table-column>
        <el-table-column prop="sourceLast" label="sourceLast"></el-table-column>

    </el-table>
    <el-form-item>
        <el-button type="primary" @click="pagination">More</el-button>
    </el-form-item>
</div>
</template>

<script>
export default {
    data() {
        return {
            items: [],
            page: 1
        }
    },
    methods: {
        async fetch() {
            const res = await this.$http.get('rest/ContactUs?page=' + this.page)
            this.items = this.items.concat(res.data.list)
        },
        async pagination() {
            this.page++;
            this.fetch();
        },

    },
    created() {
        this.fetch()
    },
}
</script>
